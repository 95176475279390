<template>
  <NuxtI18nLink
    :class="{ mobile }"
    :to="eventUrl"
    class="last-sport-bet"
    data-t="last-sport-bet"
    :title="itemTitle"
  >
    <div class="game">
      <StIcon :name="sportIcon as IconName" class="category-icon" size="24" />
      <div class="game-info">
        <div class="game-name-wrapper">
          <div class="game-name">
            <span class="game-name-inner">
              {{ itemTitle }}
            </span>
            <span v-if="isLive" class="event-score">{{ score }}</span>
            <span v-if="isExpress" class="express-counter"
              >+{{ bet.sbSportEvents.length }}</span
            >
          </div>
          <div class="info">
            <div
              v-if="isLive && marketStoreEvent && !isExpress"
              class="live-clock"
            >
              <span data-t="status">{{ matchTiming }}</span>
              <span v-if="isShownClock" data-t="match-time">{{ clock }}</span>
            </div>
            <span v-else-if="isExpress" class="express">
              {{ t('bets.express') }}
            </span>
            <span v-else class="event-time"
              >{{ startDate }} {{ startTime }}</span
            >
            <span
              v-if="tournamentName"
              :title="tournamentName"
              class="tournament-name"
              >{{ tournamentName }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- mobile off -->
    <template v-if="!mobile">
      <div class="bet-id">
        <span v-if="bet.userNickname">{{ bet.userNickname }}</span>
        <span v-else>
          <StIcon
            size="16"
            name="anonymous-solid"
            class="anonymous category-name"
          />
          {{ t('lastBets.hiddenUser') }}</span
        >
      </div>
      <div class="date">
        {{ matchDate }}
      </div>
      <div class="bet-rate">{{ truncRate(Number(props.bet.betRate)) }}X</div>
    </template>

    <div class="bet-amount">
      <div class="crypto-amount">
        <span class="crypto-amount-value">{{ formatCrypto(bet.amount) }}</span>
        <StIcon class="crypto-amount-icon" :name="currencyIcon" :size="16" />
      </div>
      <div class="fiat-amount">
        <span class="fiat-amount-value">~{{ fiatBetResult }}</span>
      </div>
    </div>
  </NuxtI18nLink>
</template>

<script setup lang="ts">
import { getSportIcon } from 'markets-store/helpers/getSportIcon'
import { parseLanguageMap, truncRate } from '@st/utils'
import { useCurrencies } from '@st/payments/stores/currencies'
import type { IconName } from '@st/ui/components/StIcon/types'
import type { Ref } from 'vue'
import type { SportLastBet } from '../types'

const props = withDefaults(
  defineProps<{
    bet: SportLastBet
    mobile?: boolean
  }>(),
  {
    mobile: false,
  },
)

const customMenu = useCustomMenu()
const firstEvent = computed(() => props.bet.sbSportEvents[0])
const currenciesStore = useCurrencies()
const { t } = useI18n()
const { locale } = useI18n()
const { parseDate } = useDate()
const { currencies } = storeToRefs(useCurrencies())
const { format: formatCrypto } = useCryptoFormatter()
const { appCurrency } = storeToRefs(currenciesStore)
const { startDate, startTime } = useStartDate(firstEvent as Ref)
const isExpress = computed(() => props.bet.sbSportEvents.length > 1)
const isLive = computed(() => firstEvent.value.status === 1)
const marketStoreEvent = useEventById(() => firstEvent.value.sbId)
const matchDate = computed(() => parseDate(props.bet.betAcceptedAt).fromNow())

const { format: formatFiat } = useCurrencyFormatter({
  currency: computed(() => appCurrency.value.code),
  signDisplay: 'never',
})

const tournamentName = computed(() => {
  const tournament = customMenu.value.findById(
    'tournament',
    firstEvent.value.sbTournamentId,
  )
  return tournament?.name
})

const sportIcon = computed(() => {
  const { sbSportId, sbCategoryId, sbTournamentId } = firstEvent.value
  if (isExpress.value) {
    return 'bet'
  }
  return getSportIcon({
    sportId: sbSportId,
    categoryId: sbCategoryId,
    tournamentId: sbTournamentId,
  })
})
const score = computed(
  () =>
    `${firstEvent.value?.matchStats?.home_score}:${firstEvent.value?.matchStats?.away_score}`,
)

const { matchTiming, clock, isShownClock } = useLiveClock(
  marketStoreEvent.value,
)

const eventUrl = computed(
  () =>
    marketStoreEvent.value?.url ??
    `/sport/${firstEvent.value.sbSportId}/${firstEvent.value.sbCategoryId}/${firstEvent.value.sbTournamentId}/match/${firstEvent.value.sbId}`,
)

const currencyIcon = computed(
  () => currencies.value?.[props.bet.currencyId]?.icon ?? '',
)
/**
 * реальная валюта
 */
const fiatBetResult = computed(() => formatFiat(props.bet.amountInAppCurrency))
/**
 * Возвращает имя/название спортсмена/клуба
 * @param qualifier
 */
function getCompetitorName(qualifier: 'away' | 'home') {
  const competitor = (firstEvent.value.competitors ?? []).find(
    (compet) => compet.qualifier === qualifier,
  )
  if (!competitor) {
    return ''
  }
  return parseLanguageMap(
    competitor.name as Record<string, string>,
    locale.value,
  )
}
const itemTitle = computed(
  () => `${getCompetitorName('home')} - ${getCompetitorName('away')}`,
)
</script>

<style scoped>
.game-name-inner {
  overflow: hidden;
  max-width: 302px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.last-sport-bet {
  cursor: pointer;

  display: flex;
  gap: var(--spacing-300);
  align-items: center;

  padding: var(--spacing-100) var(--spacing-150);

  text-decoration: none;

  background-color: var(--background-primary);
  border-radius: var(--border-radius-100);

  transition: background-color 0.3s ease-in-out;

  > * {
    flex: 10 1 0;
    min-width: 100px;
  }

  &:hover {
    background-color: var(--background-tertiary);

    .game-name-inner {
      color: var(--text-link);
    }
  }
}

.game {
  display: flex;
  flex-grow: 18;
  gap: var(--spacing-150);
  align-items: center;

  color: var(--text-secondary);
}

.category-icon {
  flex-shrink: 0;
}

.game-name-wrapper {
  overflow: hidden;
  font: var(--desktop-text-sm-medium);
  color: var(--text-primary);
  text-overflow: ellipsis;
}

.game-name {
  display: flex;
  align-items: center;
}

.game-info {
  flex-shrink: 1;
  min-width: 0;
  text-decoration: none;
}

.category-name {
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.bet-id {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.date {
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.crypto-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-sm-medium);
  color: var(--text-primary);
  text-align: right;

  &.positive {
    color: var(--system-success);
  }

  &.negative {
    color: var(--text-tertiary);
  }
}

.fiat-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: right;
}

.bet-amount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bet-rate {
  flex-grow: 5;
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
  text-align: right;
}

.event-score {
  margin-left: var(--spacing-075);
  font: var(--desktop-text-sm-semibold);
  color: var(--text-link);
}

.express-counter {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: var(--spacing-075);
  padding: var(--spacing-025) var(--spacing-050);

  font: var(--desktop-text-xs-medium);

  background: var(--background-base);
  border-radius: var(--border-radius-full);
}

.info {
  overflow: hidden;
  display: flex;
  color: var(--text-tertiary);

  * {
    white-space: nowrap;
  }
}

.tournament-name {
  overflow: hidden;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;

  &::before {
    content: '';

    display: block;

    width: 1px;
    height: 10px;
    margin: 0 var(--spacing-075);

    background-color: var(--text-disable);
  }
}

.mobile {
  .last-sport-bet {
    gap: var(--spacing-150);
    padding: 7px var(--spacing-150); /* stylelint-disable-line */

    .game {
      flex-grow: 20;
    }

    > * {
      flex: 4 1 0;
      min-width: 40px;
    }
  }

  .game-name-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-050);
    font: var(--mobile-text-medium);
  }

  .info {
    font: var(--mobile-caption-1-regular);
  }

  .crypto-amount {
    justify-content: flex-end;
    width: 100%;
    margin-left: auto;
    font: var(--mobile-text-medium);

    .crypto-amount-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .crypto-amount-icon {
      min-width: 16px;
    }
  }

  .fiat-amount {
    justify-content: flex-end;
    width: 100%;
    font: var(--mobile-caption-1-regular);

    .fiat-amount-value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .bet-amount {
    overflow: hidden;
    gap: var(--spacing-050);
    align-items: flex-start;

    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>

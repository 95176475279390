import type { CasinoLastBet } from '~/components/LastBets/types'

export function useLastBetsCasino(itemsNumber = 9) {
  interface CasinoBetDetails {
    casinoBetDetails: CasinoLastBet
  }
  const io = useSocket()

  const { data: bets, pending } = useStFetch('/casino/last-bets', {
    method: 'post',
    transform: (response) => response.slice(0, itemsNumber) as CasinoLastBet[],
  })

  function casinoBetListener(val: CasinoBetDetails) {
    if (bets.value) {
      bets.value.unshift(val.casinoBetDetails)
      bets.value.slice(0, itemsNumber)
    }
  }
  io.on('lastBetsCasinoBetDetails', casinoBetListener)

  onBeforeUnmount(() => {
    io.off('lastBetsCasinoBetDetails', casinoBetListener)
  })

  return { lastBets: bets, pending }
}
